<template>
    <component 
        :is="pageWidget" 
        :showPageTitle="showPageTitle">
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        showPageTitle: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        pageWidget() {
            if(this.isMobile) {
                return () => import('../../components/Sprint/List.vue')
            } else {
                return () => import('../../components/Sprint/ListDesctop.vue')
            }
        }
    }
}
</script>